<script setup>

</script>

<template>
  <div class="container fade-in-fwd">

    <div class="center-div">
      <router-view ></router-view>

    </div>

    <div class="left-align">
        <button class=""><router-link class="router-link" to="/">主页</router-link></button>
        <button class=""><router-link class="router-link" to="/interesting">abc</router-link></button>
        <button class=""><router-link class="router-link" to="/loser">互联网合集</router-link></button>
    </div>


  </div>
</template>

<style scoped>
@import "@/css/index.css";

.left-align {
  display: flex;
  gap: 5%;
  width: 100%;
  height: 5%;
  background-color: #ffffff;
}

.container {
  flex-direction: column;
  overflow: hidden;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100%; /* 可选：使容器充满视窗高度 */
  width: 100%;
}
.center-div {
  height: 100%;
  margin: auto;
  width: 100%;
  background-color: #68ff0e;
  border-radius: 20px;
  text-align: center; /* 可选：使内容水平居中 */
}
button{
  margin-top: 3px;
width: 100%;
  height: 100%;
  border-radius: 14px;
  color: #0d0d0d;
  background-color: #16a083;
}
button:hover:not(.active){
  height: 100%;
  width: 105%;
  background-color: #1abd9b;
  border-radius: 19px;
}
button.active{

  background-color: #ffffff;

}

.router-link{
  width: 100%;
  height: 100%;
  display: block;
  color: #000000;
  font-size: 150%;
  text-decoration: none;
}

/*动画*/
.fade-in-fwd {
  -webkit-animation: fade-in-fwd 10s ;
  animation: fade-in-fwd 10s ;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-23 8:47:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>