import {createRouter, createWebHashHistory} from 'vue-router'

import Home from "@/Home/index.vue"

const routes = [
    {
        path: '/',
        component: Home,
        children: [{
            path: '/',
            name: 'Introduce',
            component: () => import("@/Home/introduce/index.vue")
        },
            {
                path: '/interesting',
                name: 'Interesting',
                component: () => import("@/Home/Interesting/index.vue")
            },
            {
                path: '/loser',
                name: 'Loser',
                component: () => import("@/Home/loser/index.vue")
            }
        ]
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes,
})

export default router